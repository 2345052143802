<template>
	<div class="contact_us">
		<div class="contact_us_top">
			<div style="display: flex;align-items: center;">
				<div>联系我们</div>
				<img src="../assets/img/phone.png" alt="" />
				<p>19358293631</p>
			</div>
			<div style="display: flex;align-items: center;">
				<img src="../assets/img/email.png" alt="" />
				<p>tianyi@tianyi313.com</p>
			</div>
			<div style="display: flex;align-items: center;">
				<img src="../assets/img/font.png" alt="" />
				<p>河北省石家庄市长安区中山东路598号融通财金大厦</p>
			</div>
		</div>
		<div class="btn_box">
			<div class="btn_list">
				<div class="btn_title"><router-link to="/about">走进天一</router-link></div>
				<div>
					<div>企业简介</div>
					<div>企业文化</div>
					<div>资质荣誉</div>
				</div>
			</div>
			<div class="btn_list">
				<div class="btn_title"><router-link to="/product">产品服务</router-link></div>
				<div>
					<div>互联网营销推广</div>
					<div>微信定制开发</div>
					<div>APP定制开发</div>
				</div>
			</div>
			<div class="btn_list">
				<div class="btn_title"><router-link to="/classic_case">经典案例</router-link></div>
				<div>
					<div>APP案例</div>
					<div>PC端案例</div>
					<div>游戏案例</div>
				</div>
			</div>
			<div class="btn_list">
				<div class="btn_title"><router-link to="/talents">人才资源</router-link></div>
				<div>
					<div>WEB前端工程师</div>
					<div>UI设计师</div>
				</div>
			</div>
		</div>
		<div class="btn_beian">
			©2013-2024 天一数字科技（河北）有限公司 版权所有
			<a href="https://beian.miit.gov.cn" target="_blank" class="beianhao">冀ICP备13009479号-1</a>
			<img src="../assets/img/police-badge.png" alt="" />
			<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010202002913" target="_blank" class="beianhao">冀公网安备 13010202002913号</a>
		</div>
	</div>
</template>

<script></script>

<style lang="scss">
.contact_us {
	height: 25rem;
	background-color: #fafafa;
	padding: 1.5rem 10%;
	.contact_us_top {
		// display: flex;
		// align-items: center;
		color: #616161;
		border-bottom: 1px solid #e1e1e1;
		height: 5rem;
		line-height: 5rem;
		overflow: hidden;
		div {
			float: left;
			margin: 0 30px;
			img {
				margin-right: 5px;
			}
		}
	}
	.btn_box {
		// display: flex;
		// justify-content: first baseline;
		padding-left: 1.5rem;
		overflow: hidden;
		.btn_list {
			float: left;
			margin-right: 8rem;
			.btn_title {
				margin: 2rem 0;
				font-size: 1.25rem;
			}
			div {
				color: #616161;
				line-height: 2.5rem;
			}
		}
	}
	.btn_beian {
		text-align: center;
		margin-top: 3rem;
		color: #616161;
		a:hover {
			text-decoration: underline;
		}
	}
}
</style>
